import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getLoggedUserRequest } from '../../redux/actions/users.actions';
import {
  getRefreshTokenStorage,
  getTokenStorage,
  removeTokenToStorage,
} from '../../services/handleTokens';
import { openNotificationError } from '../Notification/Notification';
import TopMenu from './TopMenu/TopMenu';
import { dispatchLogin } from '../../services/tracking';
import { shopConfig } from '../../Globals/ShopConfig';
import MediaQuery from 'react-responsive';
import { createAllowedLanguagesList } from '../../Models/ShopConfig/ShopLocaleModel';
import Translator from '../../services/translator';
import audienceLogo from '../../assets/images/audience.png';
import { pagesConstant } from '../../Constants/Constants';
import { storeType } from '../../index';
import './Header.css';

export type userProfileType = {
  fullName: string;
  picture: string | null;
} | null;

const Header = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<userProfileType>(null);
  const [languages, setLanguages] = useState([]);
  const [currentLang, setCurrentLang] = useState('');
  const token = getTokenStorage();
  const refreshToken = getRefreshTokenStorage();

  const loggedUser = useSelector((state: storeType) => state.users.loggedUser);

  const getLoggedUser = useCallback(() => {
    dispatch(getLoggedUserRequest({ project_id: shopConfig.project.id }));
  }, [dispatch]);

  useEffect(() => {
    let languages = createAllowedLanguagesList(
      shopConfig.locale.allowedLanguages
    );

    setLanguages(languages);
    setCurrentLang(shopConfig.locale.language);
  }, []);

  useEffect(() => {
    if (token && refreshToken) {
      getLoggedUser();
    }
  }, [getLoggedUser, refreshToken, token]);

  useEffect(() => {
    if (loggedUser) {
      setIsLoading(loggedUser.loading);
      if (!loggedUser.loading && !loggedUser.error) {
        if (loggedUser.user) {
          dispatchLogin(loggedUser.user.id);
          setUserProfile({
            fullName: loggedUser.user.fullName,
            picture: loggedUser.user.avatar,
          });
        } else {
          setUserProfile(null);
        }
      }

      if (loggedUser.error) {
        if (loggedUser.error.status !== 401) {
          openNotificationError(
            Translator.trans('error.internal_error.title'),
            loggedUser.error.message
          );
        }
        removeTokenToStorage();
      }
    }
  }, [loggedUser]);

  return (
    <header className="main-header">
      <nav className="header-shop-container">
        <Link to={pagesConstant.index} className={'logo-event-container'}>
          <img
            className="logo-event"
            src={shopConfig.branding.logoPictureUrl ?? audienceLogo}
            alt="logo-shop"
          />
        </Link>

        <MediaQuery minWidth={769}>
          <TopMenu
            isLoading={isLoading}
            userProfile={userProfile}
            currentLang={currentLang}
            languages={languages}
            isMobile={false}
          />
        </MediaQuery>

        <MediaQuery maxWidth={768}>
          <TopMenu
            isLoading={isLoading}
            userProfile={userProfile}
            currentLang={currentLang}
            languages={languages}
            isMobile={true}
          />
        </MediaQuery>
      </nav>
    </header>
  );
};

export default Header;
