import { usersConstants } from '../constants/users.constants';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';
import ConnectedUserModel from '../../Models/ConnectedUserModel';

const initialState: { [stateName: string]: reducerItemType } = {
  loggedUser: null,
};

const users = (state = initialState, action: any) => {
  switch (action.type) {
    case usersConstants.GET_LOGGED_USER_REQUEST: {
      return {
        ...state,
        loggedUser: { user: null, loading: true, error: null },
      };
    }

    case usersConstants.GET_LOGGED_USER_SUCCESS: {
      return {
        ...state,
        loggedUser: {
          user: new ConnectedUserModel(action.logged.user),
          loading: false,
          error: null,
        },
      };
    }

    case usersConstants.GET_LOGGED_USER_FAILURE: {
      return {
        ...state,
        loggedUser: {
          user: null,
          loading: false,
          error: new ErrorModel(action.error),
        },
      };
    }

    case usersConstants.LOG_USER_OUT: {
      return {
        ...state,
        loggedUser: {
          user: null,
          loading: false,
          error: null,
        },
      };
    }

    default:
      return state;
  }
};

export default users;
