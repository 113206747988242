import { getUrl } from './httpService';
import { shopConfig } from '../Globals/ShopConfig';
import BarcodeModel from '../Models/BarcodeModel';

export const createBarcodeDownloadUrl = (barcode: BarcodeModel) => {
  let url = '';
  if (barcode.downloadHash && barcode.downloadLink) {
    url = getUrl(shopConfig.router.barcodeDownloadPdfUrl, {
      downloadHash: barcode.downloadHash,
      downloadLink: barcode.downloadLink,
    }).toString();
  }
  return url;
};
