export default class ConnectedUserModel {
  id: number;
  email: string | null;
  profileId: number;
  avatar: string | null;
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;

  constructor(data: any) {
    this.id = data.id;
    this.email = data.email;
    this.profileId = data.profile.id;
    this.avatar = data.profile.avatar;
    this.firstName = data.profile.firstName;
    this.lastName = data.profile.lastName;
    this.fullName = data.profile.fullName;
  }
}
